import HttpClient from "../Config/httpClient";

function login(payload) {
  return HttpClient({
    url: "/api/v1/users/login",
    method: "POST",
    data: {
      ...payload,
    },
  });
}
function CreateBorrower(payload) {
  return HttpClient({
    url: "/api/v1/borrower/",
    method: "POST",
    data: {
      ...payload,
    },
  });
}

function GetAllBorrower() {
  return HttpClient({
    url: "/api/v1/borrower/",
    method: "GET",
  });
}
function GetBorrowerDetails(borrowerId) {
  return HttpClient({
    url: `/api/v1/borrower/${borrowerId}`,
    method: "GET",
  });
}

function UpdateBorrowerDetails(borrowerId, payload) {
  return HttpClient({
    url: `/api/v1/borrower/${borrowerId}`,
    method: "PATCH",
    data: {
      ...payload,
    },
  });
}
function getUserDetails(userId) {
  return HttpClient({
    url: `/api/v1/users/${userId}`,
    method: "GET",
  });
}
function UpdateUserDetails(userId, payload) {
  return HttpClient({
    url: `/api/v1/users/${userId}`,
    method: "PATCH",
    data: {
      ...payload,
    },
  });
}
const Services = {
  login,
  CreateBorrower,
  GetAllBorrower,
  GetBorrowerDetails,
  UpdateBorrowerDetails,
  getUserDetails,
  UpdateUserDetails,
};

export default Services;
