import React from "react";
import "../css/css/style.scss";
import logo from "./logo.png";
import { BsList } from "react-icons/bs";
import { useState,useEffect } from "react";
import { MdClose } from "react-icons/md";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AdminNavbar() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const borrowerId = localStorage.getItem("borrowerId");
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");
  const role = localStorage.getItem("role");
  





  const handleClick = () => {
    setShow(false);
  };
  useEffect(() => {
    const role = localStorage.getItem("role");
  });

  const Logout = () => {
    localStorage.clear(navigate("/"));
    toast.success("logged out Successfully!");
  };

  return (
    <Navbar bg="light" expand="lg">
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center ">
          <a href="/#/index" className="logo ">
            <img src={logo} alt="logo" className="img-fluid " />
          </a>
          {/* <nav id="navbar" className={show ? "navbar-mobile w-100" : "navbar w-100"}> */}
          <nav id="navbar" className={show ? "navbar-mobile w-100" : "navbar justify-content-end w-100  "}>
            <ul className="w-100 justify-content-between">
              {role === "admin" ? (
                <>
                  <li>
                    <Nav.Link href="/#/dashboard" onClick={handleClick}>
                      Dashboard
                    </Nav.Link>
                  </li>

                  <li>
                    <Nav.Link href="/#/create/borrower" onClick={handleClick}>
                      Create Borrower
                    </Nav.Link>
                  </li>
                </>
              ) : null}
              {userId === null ? (
                <li className="me-5 pe-2 w-100 d-flex justify-content-end">
                  <Nav.Link href="/#/login" onClick={handleClick}>
                    LOGIN
                  </Nav.Link>
                </li>
              ) : (
                <Nav className="me-auto w-100 d-flex justify-content-end  ">
                  <NavDropdown
                    align="end"
                    title={<Avatar name={`${firstName} ${lastName}`} size="50" color="silver" round={true} />}
                    id="basic-nav-dropdown"
                  >
                    {role === "admin" ? (
                      <NavDropdown.Item
                        href={`/#/admin/edit`}
                        height="10"
                        width="45"
                        className="navbar-dropdown navbar-height edit-profile nav-font-color justify-content-xl-around-center mt-2 ms-1"
                      >
                        Edit Profile
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item
                        href={`/#/edit/${borrowerId}`}
                        height="10"
                        width="45"
                        className="navbar-dropdown navbar-height edit-profile nav-font-color justify-content-xl-around-center mt-2 ms-1"
                      >
                        Edit Profile
                      </NavDropdown.Item>
                    )}

                    <hr />
                    <NavDropdown.Item
                      onClick={() => {
                        Logout();
                        // setShow(true);
                      }}
                      className="navbar-dropdown   navbar-logout navbar-height edit-profile justify-content-evenly-center mb-3 ms-1"
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
            </ul>
            <i className="bi bi-list mobile-nav-toggle">
              {show ? (
                <MdClose onClick={() => setShow(!show)} />
              ) : (
                <BsList color="#0085cb" onClick={() => setShow(!show)} />
              )}
            </i>
          </nav>
        </div>
      </header>
    </Navbar>
  );
}

export default AdminNavbar;
